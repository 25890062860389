<template>
    <div class="buttonsWrap">
        <button class="customBtn" @click="$router.push(`/`)">Назад</button>
        <div class="customInf">
            <p class="customTitle">О сайте</p>
            <p class="customP">
                Сайт разработан при поддержке РФФИ (грант № 19-29-14019. Институт психологии РАН). Свидетельство о государственной регистрации программы для ЭВМ № 2022681734 GREATCHEMIST_RU от 16.11.2022 (Волкова Е.В., Компаниец В.С., Ковтунов И.А.). Сайт реализует технологию развития химического мышления «GreatChemist»: комплекс заданий на классификацию, установление тождества или различия, аналогию, вероятностное оценивание, выявление причинно-следственных связей.
            </p>
            <p class="customP">
                Контакты: <a href="mailto:vlad@htmlbook.ru">volkovaev@mail.ru</a> (Елена Вениаминовна Волкова, д.п.н., гл.н.с., заведующая лабораторией психологии способностей и ментальных ресурсов им. В.Н. Дружинина ИП РАН)
            </p>
            <a href="/Student_UserGuide_v01_1.pdf" style="font-size: 2.3vh; font-weight: 600; font-style:italic;">Инструкция для ученика</a><br />
            <a href="/Teacher_UserGuide_v01_1.pdf" style="font-size: 2.3vh; font-weight: 600; font-style: italic;">Инструкция для преподавателя</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'About',
        data: () => ({}),

        methods: {
            loadPage() {},
        },

        created() {
            this.loadPage()
        },
    };
</script>

<style>
    .customInf {
        background: rgba(235,255,3,0.5);
        border-radius: 26px;
        width: 901px;
        max-width: 90%;
        margin-bottom: 5%;
        margin-left: 5%;
        box-sizing: border-box;
        padding: 3% 5% 5%;
        text-align: center;
    }

    .customTitle {
        font-size: 6vh;
        font-family: ResphektRegular;
    }

    .customP {
        font-size: 3vh;
        font-family: Arial, "Nimbus Sans L", Helvetica, sans-serif;
        margin-top:20px;
    }
</style>